import fileApi from "@/apis/file";

export default {
  methods: {
    async uploadFileWithId(file) {
      try {
        let response = await fileApi.upload(file);
        return response.data.data.file.id
      } catch (error) {
        this.$swal.fire({
          title: "上傳檔案失敗",
          type: "error",
        });
      }
    },
    async uploadFile(file, name = null) {
      try {
          let response = await fileApi.upload(file, name);
          return response.data.data.file.url
      } catch (error) {
        this.$swal.fire({
          title: "上傳檔案失敗",
          type: "error",
        });
      }
    },
  }
}
