import https from "./https"

const file = {
  upload(file, file_name = null, is_temp_file = false) {
    let data = new FormData();
    data.append("file", file);
    if (file_name) {
      data.append("file_name", file_name);
    }
    if (is_temp_file) {
      data.append("is_temp_file", is_temp_file);
    }

    return https.post('general/files/upload', data)
  },
}

export default file
