import https from "./https";
import store from "@/store";

const pointLog = {
  updateCardSetting(params) {
    const organization = store.state.general.organization;

    return https.put(`/admin/organizations/${organization.id}/liff-setting/card-setting`, params);
  },

  updateUISetting(params) {
    const organization = store.state.general.organization;

    return https.put(`/admin/organizations/${organization.id}/liff-setting/ui-setting`, params);
  },
};

export default pointLog;
